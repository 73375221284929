import posthog from "posthog-js";

export default {
  install(app) {
    const posthogPlugin = posthog.init(
      'phc_NB6FkPhSr15Z3p7AcwEqGFRtCVpny68YxsDWg3fTLm5',
      {
        api_host: 'https://eu.i.posthog.com',
      }
    );

    app.config.globalProperties.$posthog = posthogPlugin;
    app.provide('posthog', posthogPlugin);
  },
};