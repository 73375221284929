<template>

    <div class="fixed inset-0 bg-gray-500  bg-opacity-75 transition-opacity" aria-hidden="true" />
    <div class="fixed z-50 inset-0 overflow-y-auto">
        <div class="h-dvh	 flex flex-col content-center items-center justify-center 	 flex-wrap	">
            <font-awesome-icon size="xl" class=" text-white  w-1/6 h-1/6" :icon="['fas', 'spinner-scale']"
                spin-pulse />
        </div>

    </div>

</template>