export default {
    install: (app) => {
      // Load the Trackdesk script
      const script = document.createElement('script');
      script.src = "//cdn.trackdesk.com/tracking.js";
      script.async = true;
      script.onload = () => {
        (function(t, d, k) {
          (t[k] = t[k] || []).push(d);
          t[d] = t[d] || t[k].f || function() {
            (t[d].q = t[d].q || []).push(arguments);
          };
        })(window, "trackdesk", "TrackdeskObject");
  
        trackdesk('coisar', 'click');

        app.config.globalProperties.$trackdesk = trackdesk;

        app.provide('trackdesk', trackdesk);
      };
      document.head.appendChild(script);
      
    }
  };