import { ref, computed, onMounted, watch } from "vue";
import { defineStore } from "pinia";
import { useApplicationStore } from '/src/stores/application';

export const useModulesStore = defineStore("modules", () => {
  const modules = ref([]);
  const currentModule = ref("");
  const currentPart = ref("");
  const currentChapter = ref(0);


  onMounted(() => {
    watch(getCurrentModule, (module) => {
      if(module?.title) {
        useApplicationStore().setTitle(module.title);
      }
    }, { deep: true });

    watch(getCurrentPart, (part) => {
      if(part?.title) {
        useApplicationStore().setSubtitle(part.title);
      }
    }, { deep: true });
  });

  function setModules(data) {
    modules.value = data;
  }

  const getModules = computed(() => {
    return modules.value;
  });

  const getChapters = computed(() => {
    return getModules.value
      .find((m) => m.slug === currentModule.value)
      .parts.find((p) => p.slug === currentPart.value).chapters;
  });

  const getCurrentModule = computed(() => {
    return getModules.value.find((m) => m.slug === currentModule.value);
  });

  const getCurrentPart = computed(() => {
    return getCurrentModule.value?.parts.find((p) => p.slug === currentPart.value);
  });

  const getCurrentChapter = computed(() => {
    const chapters = getCurrentPart.value?.chapters;
    if(chapters?.length > 0) {
      return chapters[currentChapter.value]
    }
  })



  function setCurrentModule(module) {
    currentModule.value = module;
  }

  function setCurrentPart(part) {
    currentPart.value = part;
  }

  function setCurrentChapter(chapter) {
    currentChapter.value = parseInt(chapter);
  }

  function nextChapter() {
    const chapters = getModules.value
      .find((m) => m.slug === currentModule.value)
      .parts.find((p) => p.slug === currentPart.value).chapters.length;
    if (currentChapter.value < chapters - 1) {
      currentChapter.value = parseInt(currentChapter.value) + 1;
    }
  }

  function prevChapter() {
    if (currentChapter.value - 1 >= 0) {
      currentChapter.value = parseInt(currentChapter.value) - 1;
    }
  }

  return {
    currentModule,
    currentPart,
    currentChapter,
    nextChapter,
    prevChapter,
    setCurrentModule,
    setCurrentPart,
    setCurrentChapter,
    getModules,
    getChapters,
    getCurrentModule,
    getCurrentPart,
    getCurrentChapter,
    setModules
  };
});
