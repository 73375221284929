import { ref, computed, watch, onMounted } from "vue";
import { defineStore } from "pinia";


export const useApplicationStore = defineStore("application", () => {

    const loading = ref(false);
    const name = ref("");
    const title = ref("");
    const subtitle = ref("");
    const utmParameters = ref({});
    const currentReading = ref(null);

    function setLoading(value) {
        loading.value = value;
    }

    function setName(value) {
        name.value = value;
    }

    function setTitle(value) {
        title.value = value;
    }

    function setSubtitle(value) {
        subtitle.value = value;
    }

    function setCurrentReading(value) {
        currentReading.value = value;
    }

    function setUtmParameters(query) {
        try {
            utmParameters.value = Object.keys(query).reduce((acc, key) => {
                if (key.startsWith('utm_')) {
                    acc[key.replace('utm_','')] = query[key];
                }
                return acc;
            }, {});
        } catch (e) {
            console.error(e);
        }
    }

    return {
        loading,
        name,
        title,
        subtitle,
        utmParameters,
        currentReading,
        setTitle,
        setSubtitle,
        setName,
        setLoading,
        setUtmParameters,
        setCurrentReading
    }
})